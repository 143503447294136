/* eslint-disable @typescript-eslint/no-explicit-any */
import { type App, getCurrentInstance } from 'vue'
import Rollbar from 'rollbar'
import { throwError } from './errors'
import { type CurrentUser } from '../data/user/types'
import { action } from '/@/core/tracking/api'

interface Options {
  mode: 'production' | 'development' | 'e2e'
  token: string
  env: 'local' | 'dev' | 'prod' | 'cypress'
  embed: boolean
  url: string
  version: string
}

export function useRollbar() {
  const vm = getCurrentInstance()
  if (!vm) {
    throwError(
      'Do not call `useRollbar()` outside of a component `setup()` lifecycle.',
    )
    return {
      error() {
        // noop
      },
      info() {
        // noop
      },
      configure() {
        // noop
      },
    }
  }

  return {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error(e: string | Error, ...extra: any[]) {
      vm?.appContext.app.$rollbar.error(e, ...extra)
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    info(e: string | Error, ...extra: any[]) {
      vm?.appContext.app.$rollbar.info(e, ...extra)
    },
    configure(user: CurrentUser) {
      vm?.appContext.app.$rollbar.configure({
        payload: {
          person: {
            id: user.id,
            username: `${user.firstName} ${user.lastName}`,
            email: user.email,
          },
        },
      })
    },
  }
}

// Payload defined here: https://docs.rollbar.com/reference/create-item
function extractError(payload: any) {
  if (payload.body.trace) {
    return payload.body.trace.exception.message
  }
  if (payload.body.message) {
    return payload.body.message.body
  }
  return null
}
function extractTraceSources(payload: any) {
  if (payload.body.trace) {
    return (payload.body.trace.frames as any[]).map(f => f.filename)
  }
  return []
}

const IgnoredErrors = [
  // Thrown by graphql when login tokens don't match. We display a messsage to the user
  // via a webhook on the apollo client so the logged message can be ignored.
  'Received status code 409',
  // we can safely ignore all `"Navigation aborted ..."` errors, as they are
  // produced by a call to `next(false)` inside navigation guards (e.g.
  // onBeforeRouteLeave), in case the navigation is triggered by a call to `router.
  // push()`.
  // i.e. in all cases, we meant for the navigation to be aborted, so there's
  // nothing to be warned about.
  'Navigation aborted',
  // we can ignore this errors as they happen regularly when a page is forcefully
  // refreshed while it's still loading, sending a lot of unnecessary logs
  'Importing a module script failed',
  // We don't care about errors from 3rd-party chrome extensions
  'chrome-extension',
  'Extension context invalidated',
  // Network errors
  'Load failed',
]

export default {
  install(app: App, { mode, token, env, url, version }: Options) {
    if (mode === 'development') {
      // Rollbar is messing up with source maps. Mocking it on dev.
      app.use({
        install(app) {
          app.$rollbar = {
            // @ts-ignore
            error(e: unknown, ...extra: unknown[]) {
              // eslint-disable-next-line no-console
              console.error('RollbarMock Error:', e, ...extra)
            },
            info(e: unknown, ...extra: unknown[]) {
              // eslint-disable-next-line no-console
              console.info('RollbarMock Info:', e, ...extra)
            },
            configure(options: Rollbar.Configuration) {
              // eslint-disable-next-line no-console
              console.info('RollbarMock:', 'configure', options)
            },
          }
        },
      })
    } else {
      app.use({
        install(app) {
          app.$rollbar = new Rollbar({
            accessToken: token,
            captureUncaught: true,
            captureUnhandledRejections: true,
            enabled: true,
            scrubPaths: ['body.message.extra.e.config.headers.Authorization'],
            payload: {
              environment: env,
              context: url,
              client: {
                javascript: {
                  code_version: version,
                  source_map_enabled: true,
                  guess_uncaught_frames: true,
                },
              },
            },
            // @ts-ignore
            checkIgnore(isUncaught, args, payload) {
              const errorMessage = extractError(payload)
              if (
                errorMessage &&
                IgnoredErrors.some(ignored => errorMessage.includes(ignored))
              ) {
                return true
              }
              const traceSources = extractTraceSources(payload)
              if (
                traceSources.some(source => source.includes('chrome-extension'))
              ) {
                return true
              }
              return false
            },
            onSendCallback(isUncaught, args, payload) {
              const errorMessage = extractError(payload)
              action('Error Raised', {
                url: window.location.href,
                codeVersion: version,
                uncaught: isUncaught,
                error: errorMessage,
              })
            },
          })
        },
      })
    }
  },
}
